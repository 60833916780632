import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material';
import {UserService} from 'src/app/services/user.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

  public formUser: FormGroup;
  public isLoading = false;
  public error: string;

  constructor(public dialogRef: MatDialogRef<AddUserComponent>, public userService: UserService) {
    this.formUser = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email])
    });
  }

  ngOnInit() {
  }

  submit() {
    this.isLoading = true;
    this.error = undefined;

    if (this.formUser.valid) {
      this.userService.add(
        this.formUser.get('email').value
      ).subscribe(
        response => {
          if (response.success) {
            this.dialogRef.close(true);
            this.isLoading = false;
          }
        },
        err => {
          this.error = err;
          this.isLoading = false;
        }
      );
    }
  }
}
