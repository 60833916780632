import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {MatSnackBar} from '@angular/material';
import {Router} from "@angular/router";
import {MsalService} from "@azure/msal-angular";
import {catchError, tap} from 'rxjs/internal/operators';
import {BaseService} from './base.service';
import {User} from './user.service';


export const OAuthSetting = {
  appId: 'd63cee2d-8d8b-4a79-9ed4-ec25a1748bdc',
  redirectUri: window.location.protocol + '//' + window.location.host + "/login",
  scopes: [
    'user.read'
  ]
};

@Injectable()
export class AuthenticationService extends BaseService {

  public isLogged = false;
  public error: string;
  public user: User;

  constructor(private msalService: MsalService, private http: HttpClient, public router: Router, public snackBar: MatSnackBar) {
    super(router, snackBar);
  }

  async signIn(): Promise<boolean> {
    try {
      this.error = undefined;

      let result = await this.msalService.loginPopup(OAuthSetting.scopes);

      if (result) {
        let msUser = this.msalService.getUser();

        let access_token = await this.msalService.acquireTokenSilent(OAuthSetting.scopes);

        this.user = await this.http.post<User>('/authentication/login', {access_token: access_token, email: msUser.displayableId}).toPromise();

        this.isLogged = true;
        this.error = undefined;

        return true;
      } else {
        return false;
      }
    } catch (ex) {
      this.user = undefined;
      this.isLogged = false;
      this.error = this.getErrorMessage(ex)
      return false;
    }
  }

  public checkAuthentication() {
    return this.http.get('/authentication/check').pipe(
      catchError(this.snackHttpError),
      tap((response: any) => {
        if (response.success) {
          this.user = response.user;
          this.isLogged = true;
        } else {
          this.user = undefined;
          this.isLogged = false;
        }
      })
    );
  }

  public logout() {
    return this.http.get('/authentication/logout').pipe(
      catchError(this.snackHttpError),
      tap((response: any) => {
        if (response.success) {
          this.user = undefined;
          this.isLogged = false;
        }
      })
    );
  }
}
