import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {Server, ServerService} from 'src/app/services/server.service';

@Component({
  selector: 'app-rename-server',
  templateUrl: './rename-server.component.html',
  styleUrls: ['./rename-server.component.scss']
})
export class RenameServerComponent implements OnInit {

  public formServer: FormGroup;
  public isLoading = false;
  public error: string;

  constructor(@Inject(MAT_DIALOG_DATA) public server: Server, public dialogRef: MatDialogRef<RenameServerComponent>, public serverService: ServerService) {
    this.formServer = new FormGroup({
      name: new FormControl(server.name, [Validators.required, Validators.maxLength(50)]),
      description: new FormControl(server.description, [Validators.maxLength(255)])
    });
  }

  ngOnInit() {
  }

  submit() {
    this.isLoading = true;
    this.error = undefined;

    if (this.formServer.valid) {
      this.serverService.rename(
        this.server.id,
        this.formServer.get('name').value,
        this.formServer.get('description').value,
      ).subscribe(
        response => {
          if (response.success) {
            this.dialogRef.close(true);
            this.isLoading = false;
          }
        },
        err => {
          this.error = err;
          this.isLoading = false;
        }
      );
    }
  }

}
