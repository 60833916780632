import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {Server, ServerService} from "../../../../services/server.service";

@Component({
  selector: 'app-delete-server',
  templateUrl: './delete-server.component.html',
  styleUrls: ['./delete-server.component.scss']
})
export class DeleteServerComponent implements OnInit {

  public isLoading = false;
  public error: string;

  constructor(@Inject(MAT_DIALOG_DATA) public server: Server, public dialogRef: MatDialogRef<DeleteServerComponent>, public serverService: ServerService) {
  }

  ngOnInit() {
  }

  submit() {
    this.isLoading = true;
    this.error = undefined;

    this.serverService.delete(this.server).subscribe(
      response => {
        if (response.success) {
          this.dialogRef.close(true);
          this.isLoading = false;
        }
      },
      err => {
        this.error = err;
        this.isLoading = false;
      }
    );
  }

}
