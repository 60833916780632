import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material';
import {ProjectService} from 'src/app/services/project.service';

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss']
})
export class CreateProjectComponent implements OnInit {

  public formProject: FormGroup;
  public isLoading = false;
  public error: string;

  constructor(public dialogRef: MatDialogRef<CreateProjectComponent>, public projectService: ProjectService) {
    this.formProject = new FormGroup({
      name: new FormControl(null, [Validators.required, Validators.maxLength(50)])
    });
  }

  ngOnInit() {
  }

  submit() {
    this.isLoading = true;
    this.error = undefined;

    if (this.formProject.valid) {
      this.projectService.create(
        this.formProject.get('name').value
      ).subscribe(
        response => {
          if (response.success) {
            this.dialogRef.close(true);
            this.isLoading = false;
          }
        },
        err => {
          this.error = err;
          this.isLoading = false;
        }
      );
    }
  }
}
