import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDialog, MatSnackBar, MatTableDataSource} from '@angular/material';
import {debounceTime, distinctUntilChanged} from 'rxjs/internal/operators';
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {AddServerComponent} from "./dialogs/add-server/add-server.component";
import {DeleteServerComponent} from "./dialogs/delete-server/delete-server.component";
import {Server, ServerService} from "../../services/server.service";
import {ViewDetailsComponent} from "./dialogs/view-details/view-details.component";
import {RenameServerComponent} from './dialogs/rename-server/rename-server.component';

@Component({
  selector: 'app-servers',
  templateUrl: './servers.component.html',
  styleUrls: ['./servers.component.scss']
})
export class ServersComponent implements OnInit {

  public formServer: FormGroup;
  public servers: MatTableDataSource<Server> = new MatTableDataSource<Server>();
  public displayedColumns: string[] = ['name', 'host', 'port', 'action'];
  private project_id: number;

  constructor(private serverService: ServerService, public snackBar: MatSnackBar, public dialog: MatDialog, private router: Router, private activatedRoute: ActivatedRoute) {
    this.formServer = new FormGroup({
      filter: new FormControl('')
    });
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      this.project_id = parseInt(params.get("id_project"));
      this.list();
    });

    this.formServer.get('filter').valueChanges.pipe(
      debounceTime(250),
      distinctUntilChanged()
    ).subscribe(() => {
      this.list();
    });
  }

  public goToServersList(id_project: number) {
    this.router.navigate(['/servers', id_project]);
  }

  private list() {
    this.serverService.list(
      this.project_id,
      this.formServer.get('filter').value
    ).subscribe(
      list => {
        this.servers.data = list.servers;
      }
    );
  }

  public add() {
    this.dialog.open(AddServerComponent, {width: '800px', data: this.project_id}).afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.list();
        this.snackBar.open('Server has been added successfully.', '', {duration: 5000});
      }
    });
  }

  public view(server: Server) {
    this.dialog.open(ViewDetailsComponent, {
      width: '600px',
      data: server
    });
  }

  public rename(server: Server) {
    this.dialog.open(RenameServerComponent, {width: "400px", data: server}).afterClosed().subscribe(result => {
      if (result) {
        this.list();
        this.snackBar.open('Server has been renamed successfully.', '', {duration: 5000});
      }
    });
  }

  public delete(server: Server) {
    this.dialog.open(DeleteServerComponent, {data: server}).afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.list();
        this.snackBar.open('Server has been deleted successfully.', '', {duration: 5000});
      }
    });
  }
}
