import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {User, UserService} from 'src/app/services/user.service';

@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html',
  styleUrls: ['./delete-user.component.scss']
})
export class DeleteUserComponent implements OnInit {

  public isLoading = false;
  public error: string;

  constructor(@Inject(MAT_DIALOG_DATA) public user: User, public dialogRef: MatDialogRef<DeleteUserComponent>, public userService: UserService) {
  }

  ngOnInit() {
  }

  submit() {
    this.isLoading = true;
    this.error = undefined;

    this.userService.delete(this.user).subscribe(
      response => {
        if (response.success) {
          this.dialogRef.close(true);
          this.isLoading = false;
        }
      },
      err => {
        this.error = err;
        this.isLoading = false;
      }
    );
  }
}
