import {Injectable} from '@angular/core';
import {BaseService} from "./base.service";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material";
import {Observable} from "rxjs";
import {catchError, tap} from "rxjs/operators";
import {Server} from "./server.service";

@Injectable({
  providedIn: 'root'
})
export class SSHService extends BaseService {

  private generateSSHUrl: string = '/ssh/generate';
  private downloadSSHUrl: string = '/ssh/download';

  constructor(public http: HttpClient, public router: Router, public snackBar: MatSnackBar) {
    super(router, snackBar)
  }

  public generate(id_user: number = null): Observable<any> {
    return this.http.post(this.generateSSHUrl, {id_user: id_user}, this.httpOptions).pipe(
      tap(
        response => super.handleNext(response),
        error => super.handleError(error)
      ),
      catchError(err => {
        throw this.getErrorMessage(err)
      })
    );
  }

  public download(type: string) {
    window.location.assign(this.downloadSSHUrl + "?type=" + type);
  }
}
