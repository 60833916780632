import {Injectable} from '@angular/core';
import {BaseService} from "./base.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material";
import {Observable} from "rxjs";
import {catchError, tap} from "rxjs/operators";

export interface Server {
  id: number;
  name: string;
  id_project: number;
  host: string;
  port: number;
  user: string;
  password: string;
  root_password: string;
  private_key: string;
  description: string;
  disable_ssh_with_password: boolean;
}

export interface ServerList {
  servers: Server[]
}

@Injectable({
  providedIn: 'root'
})
export class ServerService extends BaseService {

  constructor(public http: HttpClient, public router: Router, public snackBar: MatSnackBar) {
    super(router, snackBar)
  }

  public list(id_project: number, filter?: string): Observable<ServerList> {
    let params = new HttpParams().set('id_project', id_project.toString());

    if (filter) {
      params = params.set('filter', filter);
    }

    return this.http.get<ServerList>('/servers/list', {params: params}).pipe(
      tap(
        response => super.handleNext(response),
        error => super.handleError(error)
      )
    );
  }

  public rename(id_server: number, name: string, description: string): Observable<any> {
    return this.http.post('/servers/rename', {id_server: id_server, name: name, description: description}, this.httpOptions).pipe(
      tap(
        response => super.handleNext(response),
        error => super.handleError(error)
      ),
      catchError(err => {
        throw this.getErrorMessage(err)
      })
    );
  }

  public add(server: Server): Observable<any> {
    return this.http.post('/servers/add', server, this.httpOptions).pipe(
      tap(
        response => super.handleNext(response),
        error => super.handleError(error)
      ),
      catchError(err => {
        throw this.getErrorMessage(err)
      })
    );
  }

  public delete(server: Server): Observable<any> {
    return this.http.post('/servers/delete', {id_server: server.id}, this.httpOptions).pipe(
      tap(
        response => super.handleNext(response),
        error => super.handleError(error)
      ),
      catchError(err => {
        throw this.getErrorMessage(err)
      })
    );
  }

  public test_authentication(server: Server): Observable<any> {
    return this.http.post('/servers/test_authentication', server, this.httpOptions).pipe(
      tap(
        response => super.handleNext(response),
        error => super.handleError(error)
      ),
      catchError(err => {
        throw this.getErrorMessage(err)
      })
    );
  }
}
