import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/internal/operators';
import {BaseService} from './base.service';


export interface User {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
}

export interface UserList {
  users: User[]
}

@Injectable()
export class UserService extends BaseService {

  constructor(public http: HttpClient, public router: Router, public snackBar: MatSnackBar) {
    super(router, snackBar);
  }

  public list(filter?: string): Observable<UserList> {
    let params = new HttpParams();

    if (filter) {
      params = params.set('filter', filter);
    }

    return this.http.get<UserList>('/users/list', {params: params}).pipe(
      tap(
        response => super.handleNext(response),
        error => super.handleError(error)
      )
    );
  }

  public add(email: string): Observable<any> {
    return this.http.post('/users/add', {email: email}, this.httpOptions).pipe(
      tap(
        response => super.handleNext(response),
        error => super.handleError(error)
      ),
      catchError(err => {
        throw this.getErrorMessage(err)
      })
    );
  }

  public delete(user: User): Observable<any> {
    return this.http.post('/users/delete', {id_user: user.id}, this.httpOptions).pipe(
      tap(
        response => super.handleNext(response),
        error => super.handleError(error)
      ),
      catchError(err => {
        throw this.getErrorMessage(err)
      })
    );
  }
}
