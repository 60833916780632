import {NgModule} from '@angular/core';
import {RouterModule, Routes, UrlSegment, UrlMatchResult} from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {ProjectsComponent} from './components/projects/projects.component';
import {AuthGuardService} from './services/auth-guard.service';
import {ServersComponent} from "./components/servers/servers.component";
import {UsersComponent} from "./components/users/users.component";

const routes: Routes = [
  {path: '', redirectTo: '/projects', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'projects', component: ProjectsComponent, canActivate: [AuthGuardService]},
  {path: 'users', component: UsersComponent, canActivate: [AuthGuardService]},
  {path: 'servers/:id_project', component: ServersComponent, canActivate: [AuthGuardService]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
