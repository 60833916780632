import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {ServerService} from 'src/app/services/server.service';

@Component({
  selector: 'app-add-server',
  templateUrl: './add-server.component.html',
  styleUrls: ['./add-server.component.scss']
})
export class AddServerComponent implements OnInit {

  public formServer: FormGroup;
  public error: string;
  public testError: string;
  public isValid: boolean = false;
  public isTested: boolean = false;
  public isLoading: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public id_project: number, public dialogRef: MatDialogRef<AddServerComponent>, public serverService: ServerService) {
    this.formServer = new FormGroup({
      name: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
      id_project: new FormControl(this.id_project, [Validators.required]),
      host: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
      port: new FormControl(null, [Validators.required, Validators.min(1), Validators.max(65535)]),
      user: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
      password: new FormControl({value:null, disabled:false}, [Validators.required, Validators.maxLength(100)]),
      private_key: new FormControl({value:null, disabled:true}),
      auth_type: new FormControl('1', [Validators.required]),
      description: new FormControl(null, [Validators.maxLength(255)]),
      disable_ssh_with_password: new FormControl(true, [Validators.required])
    });
  }

  ngOnInit() {
    this.formServer.get('auth_type').valueChanges.subscribe(selected_value => {
      if(selected_value == 1){
        this.formServer.get('private_key').disable();
        this.formServer.get('password').enable();
        this.formServer.get('password').setValidators([Validators.required, Validators.maxLength(100)]);
      }
      else {
        this.formServer.get('password').disable();
        this.formServer.get('private_key').enable();
        this.formServer.get('private_key').setValidators([Validators.required]);
      }
    })
  }

  testAuthentication() {
    this.isTested = false;
    this.testError = null;

    if(this.formServer.valid){
      Object.keys(this.formServer.value).forEach((key) => (this.formServer.value[key] == null) && delete this.formServer.value[key]);
      this.serverService.test_authentication(this.formServer.value).subscribe(res => {
        this.isTested = true;

        if(res.success){
          this.isValid = true;
        }
        else {
          this.isValid = false;
          this.testError = res.error;
        }
      });
    }
  }

  submit() {
    this.error = undefined;
    this.isLoading = true;

    if (this.formServer.valid && this.isValid) {
      this.serverService.add(
        this.formServer.value
      ).subscribe(
        response => {
          if (response.success) {
            this.isLoading = false;
            this.dialogRef.close(true);
          }
        },
        err => {
          this.isLoading = false;
          this.error = err;
        }
      );
    }
  }
}
