import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MsalModule} from '@azure/msal-angular';
import {AppRoutingModule} from './app-routing.module';
import {MaterialModule} from './core/material.module';

import {AuthenticationService, OAuthSetting} from './services/authentication.service';
import {AuthGuardService} from './services/auth-guard.service';
import {ThemeService} from './services/theme.service';
import {ProjectService} from './services/project.service';
import {SSHService} from './services/ssh.service';
import {ServerService} from './services/server.service';

import {AppComponent} from './app.component';
import {LoginComponent} from './components/login/login.component';
import {CreateProjectComponent} from './components/projects/dialogs/create-project/create-project.component';
import {DeleteProjectComponent} from './components/projects/dialogs/delete-project/delete-project.component';
import {ProjectsComponent} from './components/projects/projects.component';
import {HeaderComponent} from './header/header.component';
import {ServersComponent} from './components/servers/servers.component';
import {AddServerComponent} from './components/servers/dialogs/add-server/add-server.component';
import {DeleteServerComponent} from './components/servers/dialogs/delete-server/delete-server.component';
import {RenameProjectComponent} from './components/projects/dialogs/rename-project/rename-project.component';
import {ViewDetailsComponent} from './components/servers/dialogs/view-details/view-details.component';
import {UsersComponent} from './components/users/users.component';
import { UserService } from './services/user.service';
import { DeleteUserComponent } from './components/users/dialogs/delete-user/delete-user.component';
import { AddUserComponent } from './components/users/dialogs/add-user/add-user.component';
import { RenameServerComponent } from './components/servers/dialogs/rename-server/rename-server.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ProjectsComponent,
    HeaderComponent,
    CreateProjectComponent,
    DeleteProjectComponent,
    ServersComponent,
    AddServerComponent,
    DeleteServerComponent,
    RenameProjectComponent,
    ViewDetailsComponent,
    UsersComponent,
    DeleteUserComponent,
    RenameServerComponent,
    AddUserComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MsalModule.forRoot({
      clientID: OAuthSetting.appId,
      redirectUri: OAuthSetting.redirectUri
    })
  ],
  providers: [
    ThemeService,
    AuthenticationService,
    AuthGuardService,
    ProjectService,
    ServerService,
    SSHService,
    UserService
  ],
  entryComponents: [
    CreateProjectComponent,
    DeleteProjectComponent,
    AddServerComponent,
    DeleteServerComponent,
    RenameProjectComponent,
    ViewDetailsComponent,
    DeleteUserComponent,
    RenameServerComponent,
    AddUserComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
