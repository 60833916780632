import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/internal/operators';
import {BaseService} from './base.service';


export interface Project {
  id: number;
  name: string;
  servers: number;
}

export interface ProjectList {
  projects: Project[]
}

@Injectable()
export class ProjectService extends BaseService {

  constructor(public http: HttpClient, public router: Router, public snackBar: MatSnackBar) {
    super(router, snackBar);
  }

  public list(filter?: string): Observable<ProjectList> {
    let params = new HttpParams();

    if (filter) {
      params = params.set('filter', filter);
    }

    return this.http.get<ProjectList>('/projects/list', {params: params}).pipe(
      tap(
        response => super.handleNext(response),
        error => super.handleError(error)
      )
    );
  }

  public create(name: string): Observable<any> {
    return this.http.post('/projects/create', {name: name}, this.httpOptions).pipe(
      tap(
        response => super.handleNext(response),
        error => super.handleError(error)
      ),
      catchError(err => {
        throw this.getErrorMessage(err)
      })
    );
  }

  public rename(id_project: number, name: string): Observable<any> {
    return this.http.post('/projects/rename', {id_project: id_project, name: name}, this.httpOptions).pipe(
      tap(
        response => super.handleNext(response),
        error => super.handleError(error)
      ),
      catchError(err => {
        throw this.getErrorMessage(err)
      })
    );
  }

  public delete(project: Project): Observable<any> {
    return this.http.post('/projects/delete', {id_project: project.id}, this.httpOptions).pipe(
      tap(
        response => super.handleNext(response),
        error => super.handleError(error)
      ),
      catchError(err => {
        throw this.getErrorMessage(err)
      })
    );
  }
}
