import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {ProjectService, Project} from "../../../../services/project.service";

@Component({
  selector: 'app-rename-project',
  templateUrl: './rename-project.component.html',
  styleUrls: ['./rename-project.component.scss']
})
export class RenameProjectComponent implements OnInit {

  public formProject: FormGroup;
  public isLoading = false;
  public error: string;

  constructor(@Inject(MAT_DIALOG_DATA) public project: Project, public dialogRef: MatDialogRef<RenameProjectComponent>, public projectService: ProjectService) {
    this.formProject = new FormGroup({
      name: new FormControl(project.name, [Validators.required, Validators.maxLength(50)])
    });
  }

  ngOnInit() {
  }

  submit() {
    this.isLoading = true;
    this.error = undefined;

    if (this.formProject.valid) {
      this.projectService.rename(
        this.project.id,
        this.formProject.get('name').value
      ).subscribe(
        response => {
          if (response.success) {
            this.dialogRef.close(true);
            this.isLoading = false;
          }
        },
        err => {
          this.error = err;
          this.isLoading = false;
        }
      );
    }
  }

}
