import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Project, ProjectService} from 'src/app/services/project.service';

@Component({
  selector: 'app-delete-project',
  templateUrl: './delete-project.component.html',
  styleUrls: ['./delete-project.component.scss']
})
export class DeleteProjectComponent implements OnInit {

  public isLoading = false;
  public error: string;

  constructor(@Inject(MAT_DIALOG_DATA) public project: Project, public dialogRef: MatDialogRef<DeleteProjectComponent>, public projectService: ProjectService) {
  }

  ngOnInit() {
  }

  submit() {
    this.isLoading = true;
    this.error = undefined;

    this.projectService.delete(this.project).subscribe(
      response => {
        if (response.success) {
          this.dialogRef.close(true);
          this.isLoading = false;
        }
      },
      err => {
        this.error = err;
        this.isLoading = false;
      }
    );
  }
}
