import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDialog, MatSnackBar, MatTableDataSource} from '@angular/material';
import {User, UserService} from 'src/app/services/user.service';
import {Router} from '@angular/router';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {DeleteUserComponent} from './dialogs/delete-user/delete-user.component';
import { AddUserComponent } from './dialogs/add-user/add-user.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  public formUser: FormGroup;
  public users: MatTableDataSource<User> = new MatTableDataSource<User>();
  public displayedColumns: string[] = ['firstname', 'lastname', 'email', 'action'];

  constructor(private userService: UserService, public snackBar: MatSnackBar, public dialog: MatDialog, private router: Router) {
    this.formUser = new FormGroup({
      filter: new FormControl('')
    });
  }

  ngOnInit() {
    this.list();

    this.formUser.get('filter').valueChanges.pipe(
      debounceTime(250),
      distinctUntilChanged()
    ).subscribe(() => {
      this.list();
    });
  }

  private list() {
    this.userService.list(
      this.formUser.get('filter').value
    ).subscribe(
      list => {
        this.users.data = list.users;
      }
    );
  }

  public add() {
    this.dialog.open(AddUserComponent, {width: "400px"}).afterClosed().subscribe(result => {
      if (result) {
        this.list();
        this.snackBar.open('User has been added successfully.', '', {duration: 5000});
      }
    });
  }

  public delete(user: User) {
    this.dialog.open(DeleteUserComponent, {data: user}).afterClosed().subscribe(result => {
      if (result) {
        this.list();
        this.snackBar.open('User has been deleted successfully.', '', {duration: 5000});
      }
    });
  }
}
