import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from 'src/app/services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public isLoading = false;

  constructor(public authenticationService: AuthenticationService, private router: Router) { }

  ngOnInit() {
  }

   async snap() {
    const glove = document.querySelector('.infinity');
    const snapBanner = document.querySelector('.snap');

    glove.className = 'hide';
    snapBanner.className = 'snap';

    await this.resolveAfter1Seconds();

    glove.className = 'infinity';
    snapBanner.className = 'snap hide';

    this.signIn();
  }

  public resolveAfter1Seconds() {
      return new Promise(
          resolve => {
              setTimeout(() => { resolve(); }, 1000 );
      });
  }

  async signIn() {
    this.isLoading = true;

    await this.authenticationService.signIn();

    if (this.authenticationService.isLogged) {
      this.router.navigate(['/projects']);
    }

    this.isLoading = false;
  }
}
