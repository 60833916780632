import {OverlayContainer} from '@angular/cdk/overlay';
import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/internal/operators';
import {Theme, ThemeService} from './services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public theme: Theme;
  public themes: Theme[] = [];
  public currentUrl: string;

  constructor(private themeService: ThemeService, private overlayContainer: OverlayContainer, public route: Router) {
    route.events.pipe(
      filter(e => e instanceof NavigationEnd)
    ).subscribe((e: NavigationEnd) => this.currentUrl = e.urlAfterRedirects);

    this.theme = this.themeService.getDefaultTheme();
    this.overlayContainerTheming(this.theme.value);
  }

  public ngOnInit() {
    this.themes = this.themeService.getThemes();
    this.themeService.getTheme().subscribe((next) => {
      this.theme = next;
    });
  }

  private overlayContainerTheming(themeClassName: string) {
    const cl = this.overlayContainer.getContainerElement().classList;

    if (cl.length > 1) {
      this.overlayContainer.getContainerElement().classList.remove(cl.item(cl.length - 1));
    }

    this.overlayContainer.getContainerElement().classList.add(themeClassName);
  }
}
