import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatDialog, MatSnackBar, MatTableDataSource} from '@angular/material';
import {debounceTime, distinctUntilChanged} from 'rxjs/internal/operators';
import {Project, ProjectService} from 'src/app/services/project.service';
import {CreateProjectComponent} from './dialogs/create-project/create-project.component';
import {DeleteProjectComponent} from './dialogs/delete-project/delete-project.component';
import {RenameProjectComponent} from "./dialogs/rename-project/rename-project.component";
import {Router} from "@angular/router";
import { SSHService } from 'src/app/services/ssh.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  public formProject: FormGroup;
  public projects: MatTableDataSource<Project> = new MatTableDataSource<Project>();
  public displayedColumns: string[] = ['name', 'servers', 'action'];

  constructor(private projectService: ProjectService, public sshService: SSHService, public snackBar: MatSnackBar, public dialog: MatDialog, private router: Router) {
    this.formProject = new FormGroup({
      filter: new FormControl('')
    });
  }

  ngOnInit() {
    this.list();

    this.formProject.get('filter').valueChanges.pipe(
      debounceTime(250),
      distinctUntilChanged()
    ).subscribe(() => {
      this.list();
    });
  }

  public goToServersList(id_project: number) {
    this.router.navigate(['/servers', id_project]);
  }

  private list() {
    this.projectService.list(
      this.formProject.get('filter').value
    ).subscribe(
      list => {
        this.projects.data = list.projects;
      }
    );
  }

  public create() {
    this.dialog.open(CreateProjectComponent).afterClosed().subscribe(result => {
      if (result) {
        this.list();
        this.snackBar.open('Project has been created successfully.', '', {duration: 5000});
      }
    });
  }

  public rename(project: Project) {
    this.dialog.open(RenameProjectComponent, {data: project}).afterClosed().subscribe(result => {
      if (result) {
        this.list();
        this.snackBar.open('Project has been renamed successfully.', '', {duration: 5000});
      }
    });
  }

  public delete(project: Project) {
    this.dialog.open(DeleteProjectComponent, {data: project}).afterClosed().subscribe(result => {
      if (result) {
        this.list();
        this.snackBar.open('Project has been deleted successfully.', '', {duration: 5000});
      }
    });
  }

  public generate() {
    this.snackBar.open('Regenerating a new key. Please wait...', '', {duration: 5000});

    this.sshService.generate().subscribe(
      response => {
        if (response.success) {
          this.snackBar.open('Your key has been regenerated successfully.', '', {duration: 5000});
        }
      },
      err => {
        this.snackBar.open(err, '', {duration: 5000});
      }
    );
  }
}
