import {Injectable} from '@angular/core';

import * as _ from 'lodash';
import {Observable, Subject} from 'rxjs';

export interface Theme {
  value: string;
  viewValue: string;
}

@Injectable()
export class ThemeService {

  private _themes: Theme[] = [
    {value: 'iprospect-green-light-theme', viewValue: 'iProspect Green'},
    {value: 'iprospect-green-dark-light-theme', viewValue: 'iProspect Dark Green'},
  ];

  private _theme: Subject<Theme> = new Subject<Theme>();

  public getThemes() {
    return this._themes;
  }

  public getDefaultTheme(): Theme {
    return this._themes[0];
  }

  public getTheme(): Observable<Theme> {
    return this._theme;
  }

  public setTheme(selectedTheme: string) {
    const _theme = _.find(this._themes, {value: selectedTheme});
    this._theme.next(_theme);
  }
}
